import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import Video from "../../components/Video"
import Introduction from "../../components/Introduction"
import Carousel from "../../components/Carousel"
import VideoDetail from "../../components/VideoDetail"
import SpecsTable from "../../components/SpecsTable"
import FormContact from "../../components/FormContact"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CarouselMobile from "../../components/Carousel/mobile"
import FeatureLineMobile from "../../components/FeatureLine/mobile"
import VideoDetailMobile from "../../components/VideoDetail/mobile"
import FeatureLine from "../../components/FeatureLine"

// ASSETS
import EstufasVideo from "../../assets/videos/Estufas/estufas.mp4"
import EstufasVideoPreview from "../../assets/videos/Estufas/estufas-preview.mp4"
import EstufaDetailVideo from "../../assets/videos/Estufas/estufas-detail-2.mp4"
import EstufaDetailVideo2 from "../../assets/videos/Estufas/estufas-gif.mp4"
import EstufaDetailVideo3 from "../../assets/videos/Estufas/estufas-detail-1.mp4"
import EstufaDetailVideoMobile from "../../assets/videos/Estufas/mobile/estufas-detail-2-mobile.mp4"
import EstufaDetailVideoMobile2 from "../../assets/videos/Estufas/mobile/estufas-detail-1-mobile.mp4"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

const IndexPage = ({ location }) => {
  const {t} = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language

  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
      <SEO
        pathname={location.pathname}
        title={t('meta', { returnObjects: true })['title']}
        keywords={t('meta', { returnObjects: true })['keywords']}
        description={t('meta', { returnObjects: true })['description']}
        img={t('meta', { returnObjects: true })['image']}
        imageHeight="628"
        imageWidth="1200"
        lang={activeLanguage}
        domain={location?.hostname}
      />
      <Video
        className="full-width full-height scrollable"
        srcp={EstufasVideo}
        src={EstufasVideoPreview}
        poster="https://ramalhos.com/video_posters/Estufas/estufas.jpg"
        width="100%"
        id="video-estufas"
        imgMobile={t('video', { returnObjects: true })['bgMobile']}
        fullString={t('videoHome', { returnObjects: true })['info']}
        toClick="#introduction"
      />

      <Introduction
        className="scrollable"
        id="introduction"
        translation={t('introduction', { returnObjects: true })}
      />

      {/* IF MOBILE */}
      {breakpoints.tl ? (
        <CarouselMobile
          showArrows={false}
          showIndicators={true}
          autoPlay={false}
          transitionTime={0}
          id="carousel-estufa-mobile"
          carouselContent={t('carousel', { returnObjects: true })}
        />
      ) : (
        //IF NOT
        <Carousel
          showArrows={false}
          showIndicators={true}
          autoPlay={false}
          transitionTime={0}
          id="carousel-estufa"
          carouselContent={t('carousel', { returnObjects: true })}
        />
      )}

      {/* DESKTOP  */}

      {!breakpoints.tl && (
        <>
          <VideoDetail
            src={EstufaDetailVideo}
            poster="https://ramalhos.com/video_posters/Estufas/estufas-detail-1.jpg"
            id="video-detail-estufa"
            _title={t('video', { returnObjects: true })['title1']}
            text1={t('video', { returnObjects: true })['text1_1']}
          />

          <VideoDetail
            src={EstufaDetailVideo2}
            poster="https://ramalhos.com/video_posters/Estufas/estufas-gif.jpg"
            id="video-detail-estufa2"
            _title={t('video', { returnObjects: true })['title2']}
            text1={t('video', { returnObjects: true })['text2_1']}
            positionCenter
          />

          <VideoDetail
            src={EstufaDetailVideo3}
            poster="https://ramalhos.com/video_posters/Estufas/estufas-detail-2.jpg"
            id="video-detail-estufa3"
            _title={t('video', { returnObjects: true })['title3']}
            text1={t('video', { returnObjects: true })['text3_1']}
          />

          <FeatureLine
            id="feature-line-estufa"
            _title={t('feature', { returnObjects: true })['title1']}
            height="unset"
            paddingTop="100px"
            noImage
            noBar
          />
        </>
      )}

      {/* --- */}

      {/* MOBILE */}

      {breakpoints.tl && (
        <>
          <VideoDetailMobile
            src={EstufaDetailVideoMobile}
            poster="https://ramalhos.com/video_posters/Estufas/mobile/estufas-detail-1-mobile.jpg"
            id="video-detail-estufa-mobile"
            _title={t('video', { returnObjects: true })['title1']}
            text={t('video', { returnObjects: true })['text1_1']}
            positionAbsolute
            videoOpacity
          />

          <FeatureLineMobile
            id="first-line-mobile"
            _title={t('video', { returnObjects: true })['title2']}
            text1={t('video', { returnObjects: true })['text2_1']}
            image={t('feature', { returnObjects: true })['image1_1M']}
            positionTopBottom
          />

          <VideoDetailMobile
            src={EstufaDetailVideoMobile2}
            poster="https://ramalhos.com/video_posters/Estufas/mobile/estufas-detail-2-mobile.jpg"
            id="video-detail-estufa-mobile2"
            _title={t('video', { returnObjects: true })['title3']}
            text={t('video', { returnObjects: true })['text3_1']}
            positionAbsolute
            videoOpacity
          />

          <VideoDetailMobile
            id="title-estufas"
            _title={t('feature', { returnObjects: true })['title2']}
            smallHeight
          />
        </>
      )}

      {/* --- */}

      <SpecsTable
        id="spec-table"
        specsTable={t('specs', { returnObjects: true })['content']}
        specsLength={t('specs', { returnObjects: true })['content'].length}
        location={location}
        svgWidth="190px"
      />

        {!(activeLanguage === 'br') &&
            <FormContact 
                id="form-contact" 
                info={t('form', { returnObjects: true })}
                lang={activeLanguage} 
                location={location}
            />
        }
    </Layout>
  )
}

export default IndexPage

export const QueryEstufaPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "estufa", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
